
import { Component, Vue } from "vue-property-decorator";
import "../assets/sass/master.scss";

@Component({
  components: {},
})
export default class BaseLayout extends Vue {
  protected name = "BaseLayout";
}
